import { c as r } from "./index-BV2fynjD.js";
const a = r(
  `outline-0 ml-2 
  placeholder:text-gray-neutral-70 
  flex w-full 
  bg-transparent py-2 
  ring-offset-background file:border-0 file:bg-transparent file:font-medium placeholder:font-normal  focus-visible:outline-0  disabled:cursor-not-allowed disabled:opacity-50 border-0`,
  {
    variants: {
      variant: {
        sm: "h-8 text-sm placeholder:text-sm",
        xmd: "h-10 text-sm placeholder:text-sm",
        md: "h-10 text-sm placeholder:text-sm",
        lg: "h-16 text-lg placeholder:text-lg",
        grow: "field-content text-md placeholder:text-md"
      }
    },
    defaultVariants: {
      variant: "md"
    }
  }
), t = r(
  "flex flex-row rounded-md border border-gray-neutral-80 transition-colors bg-transparent items-center px-3 box-border aria-disabled:bg-gray-neutral-20 aria-disabled:border-gray-neutral-30",
  {
    variants: {
      variant: {
        sm: "h-8",
        md: "h-10",
        xmd: "h-10",
        lg: "h-16"
      },
      error: {
        true: "border-destructive"
      }
    },
    defaultVariants: {
      variant: "md",
      error: !1
    }
  }
), l = r(
  "flex flex-row rounded-md border border-gray-neutral-80 bg-transparent items-center px-3 box-border aria-disabled:bg-gray-neutral-20 aria-disabled:border-gray-neutral-30",
  {
    variants: {
      error: {
        true: "border-destructive"
      }
    },
    defaultVariants: {
      error: !1
    }
  }
);
export {
  a as I,
  l as T,
  t as W
};
